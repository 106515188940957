import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": "https://netbistrot.com/en/",
        url: "https://netbistrot.com/en/",
        name: "NetBistrot",
        inLanguage: "en-US",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency"
        description="Great communication for small business."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="wrapper z-0">
        <PageHeader />
        <div className="main mx-2">
          <div className="section section-typo">
            <div className="mx-auto max-w-4xl">
              <div className="text-center ">
                <span className="text-4xl">
                  Great communication for small business.
                </span>
              </div>
              <div className="h-8" />
              {/* <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-2" />
                <div className=" col-span-8">
                  <p className="text-2xl">What we do:</p>
                  <div className="h-4" />
                  <p className="text-2xl pb-4">High performance websites</p>
                  <p className="text-2xl">Pretty fast page loading</p>
                  <p className="text-xl pl-6">improved user experience</p>
                  <p className="text-xl pl-6">improved user engagement</p>
                  <p className="text-xl pl-6">improved SEO ranking position</p>
                  <div className="h-4" />
                  <p className="text-2xl">Lower electrical consumption</p>
                  <p className="text-xl pl-6">environmental responsibility</p>
                  <div className="h-4" />
                  <p className="text-2xl">
                    Lower maintenance and ownership costs
                  </p>
                  <div className="h-4" />
                  <p className="text-2xl">Search engine optimization</p>
                  <p className="text-xl pl-6">improved ranking position</p>
                  <div className="h-4" />
                  <p className="text-2xl">Scalability</p>
                  <p className="text-xl pl-6">
                    ensure access even on high demand
                  </p>
                  <div className="h-8" />
                  <Link
                    className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                    to="/en/static-websites/"
                  >
                    How we do it?
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          <div className="h-20" />
        </div>
        <Footer lang="en" />
      </div>
    </>
  )
}

export default IndexEN
